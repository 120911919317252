.reasons_card{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 50px 0;
}

.reasons_title>h4{
    padding: 25px 0;
    font-family: var(--font-heading-family);
    font-style: var(--font-heading-style);
    font-weight: var(--font-heading-weight);
    color: var(--gan-white);
}
.card_wrapper{
    display: flex;
    padding: 25px 20px;
}
.cards{
    display: flex;
    column-gap: 30px;
    row-gap: 30px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.single_reason{
    width: auto;
    height: 100%;
    padding: 35px;
    max-width: 350px;
    min-width: 250px;
    text-align: center;
    background-color: var(--gan-white);
    color: var(--gan-black);
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
}
.single_reason>p{
    padding: 20px 0;
}
.single_reason>h3{
    font-family: var(--font-heading-family);
    font-style: var(--font-heading-style);
    font-weight: var(--font-heading-weight);
    font-size: var(--ltext);
}

@media screen and (max-width: 1150px){
 .single_reason {
    height: auto !important;
}
}